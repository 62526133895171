<template>
    <div>
        <div class="table-details-content">
            <PageHeader>
                <template v-slot:header>
                    <b-skeleton v-if="!brasindiceProp" animation="fade" width="300px"></b-skeleton>
                    <div v-else>
                        <div>{{ brasindiceProp.item }}</div>
                        <div class="apres">Exceções brasindice por convênio</div>
                    </div>
                </template>
                <template v-slot:actions>
                    <div class="actions">
                        <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getBrasindiceExceptions()"/>
                        <div 
                            v-if="selecteds.length" 
                            class="batch-delete-wrapper" 
                            v-b-tooltip.hover title="Excluir items"
                            @click="deleteSelecteds"
                        >
                            <b-badge class="badge" variant="danger">{{ selecteds.length }}</b-badge>
                            <Delete class="icon" />
                        </div>
                        <b-button 
                            variant="primary"
                            :disabled="loading"
                            @click="openModal"
                        >
                            Nova exceção
                        </b-button>
                    </div>
                </template>
            </PageHeader>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-group>
                        <b-form-input 
                            v-model="query" 
                            placeholder="Digite um convênio para pesquisar..."
                            @input="searchDebounce"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <TableExceptions 
                :exceptions="exceptions"
                :selecteds="selecteds"
                @delete="deleteItem"
                @handlerMasterSelect="handlerMasterSelect"
                @handlerSelect="handlerSelect"
                @updateActive="updateActive"
            />

            <div class="pagination-position">
                <b-pagination
                    v-model="page"
                    :total-rows="count"
                    :per-page="limit"
                    first-number
                    last-number
                    size="sm"
                />
            </div>
        </div>

        <BrasindiceExceptionModal
            :brasindiceProp="brasindiceProp"
            :item="item"
            @saved="onSaved"
        />
    </div>
</template>
<script>
import { debounce } from 'lodash';
import brasindiceApi from '@matMed/brasindice.api'
import api from '@matMed/brasindice-exception.api'
import * as CONSTANTS from '@tables/utils/constants'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    props: {
        multiPages: Boolean,
        table: Object,
    },
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),   
        Delete: () => import('@/assets/icons/delete.svg'), 
        PageHeader: () => import('@/components/General/PageHeader'),
        TableExceptions: () => import('@matMed/layouts/TableExceptions'),
        BrasindiceExceptionModal: () => import('@matMed/modals/BrasindiceExceptionModal')
    },
    async mounted() {
        this.searchDebounce = debounce(this.getBrasindiceExceptions, 300);
        await this.getBrasindiceProps()
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            CONSTANTS,
            loading: false,
            brasindiceProp: null,
            brasindicePropId: this.$route.params.id,
            exceptions: [],
            item: null,
            selecteds: [],
            query: null,
            page: 0,
            count: 0,
            limit: 0,
            searchDebounce: Function
        }
    },
    methods: {
        openModal() {
            this.$bvModal.show('brasindice-exceptions-modal')
        },
        async getBrasindiceProps() {
            this.loading = true
            try {
                const { data } = await brasindiceApi.show(this.clinic.id, this.$route.params.id);
                this.brasindiceProp = data
                this.getBrasindiceExceptions()
            } catch(error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        },
        getBrasindiceExceptions() {
            if (!this.brasindiceProp?.id) return
            this.loading = true
            api.getItems(this.page, this.clinic.id, this.brasindiceProp.id, this.query)
            .then(({ data }) => {
                this.exceptions = data.data
                this.count = data.total
                this.limit = data.per_page
            })
            .catch(error => this.$toast.error(error.message))
            .finally(() => (this.loading = false));
        },
        onSaved(item) {
            const index = this.exceptions.findIndex(({ id }) => id === item.id)
            index >= 0 ?
                this.exceptions.splice(index, 1, item) :
                this.exceptions.push(item)
        },
        async updateActive({ exception, active }) {
            this.loading = true
            try {
                await api.update(exception.id, { active })
                this.$toast.success(`Status da exceção atualizado com sucesso!`)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.validated = false
                this.loading = false
            }
        },
        async deleteItem(item) {
            try {
                await api.delete(item.id)
                this.$toast.success('Item excluído com sucesso')
                const index = this.exceptions.findIndex(({ id }) => id === item.id)
                this.exceptions.splice(index, 1)
                this.selecteds = [];
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                
            }
        },
        deleteSelecteds() {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir os <span>${this.selecteds.length} itens</span> selecionados?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await api.deleteBatch(this.selecteds)
                        this.$toast.success('Itens excluídos com sucesso')
                        this.selecteds.forEach(id => {
                            const index = this.exceptions.findIndex(item => item.id === id)
                            this.exceptions.splice(index, 1)
                            this.page !== 1 && (this.page = 1)
                        })
                        this.selecteds = []
                    } catch (error) {
                        this.$toast.error(error.message)
                    }
                }
            })
        },
        async handlerMasterSelect(checked) {
            if (!checked) {
                this.selecteds = []
            } else if (!this.multiPages) {
                this.selecteds = this.exceptions.map(({ id }) => id)
            } else {
                this.loading = true
                try {
                    const { data } = await api.getIds(this.clinic.id, this.brasindiceProp.id)
                    this.selecteds = data
                } catch (error) {
                    this.$toast.error(error.message)
                } finally {
                    this.loading = false
                }
            }
        },
        handlerSelect(item) {
            const index = this.selecteds.findIndex(id => id === item.id)
            index >= 0 ?
                this.selecteds.splice(index, 1) :
                this.selecteds.push(item.id)
        }
    },
    watch: {
        page() {
            this.getBrasindiceExceptions()
        }
    }
}
</script>
<style lang="scss" scoped>
.table-details-content {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    padding: 1rem;
}
.apres {
    font-size: 0.8rem;
    color: var(--neutral-400);
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}
.batch-delete-wrapper {
    position: relative;
    cursor: pointer;

    .badge {
        position: absolute;
        right: -6px;
        top: -4px;
    }
}
</style>